import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { post } from '../../api/apiUtils';
import { UploadedFileData } from '../../mocks/data/fileData';
import { CollapsibleSection } from '../../components/CollapsibleSection/CollapsibleSection';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpiner';
import styles from './FileDetails.module.css';
import { NGramAnalysisViewer } from '../../components/NGramVisualizer/NGramVisualizer';
import { AlertDialog } from '../../components/AlertDialog/AlertDialog';
import {
  TAuthorNGrams,
  TBookDetails,
  TBookEvalCharacter,
  TBookEvalOverall,
  TBookEvalPlot,
  TBookEvalPromise,
  TBookEvalQuality,
  TLlmModel,
} from '../../api/generated/types/common';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducers/rootReducer';
import StoryBeatVisualizer from '../../components/StoryBeatVisualizer/StoryBeatVisualizer';
import EvaluationSection from '../../components/EvaluationSection/EvaluationSection';

export const FileDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [fileDetails, setFileDetails] = useState<UploadedFileData | null>(null);
  const [currentVersionDetails, setCurrentVersionDetails] =
    useState<TBookDetails | null>(null);
  const [llmModel, setLlmModel] = useState<TLlmModel>('gpt-4o-mini');
  const llmModelOptions: TLlmModel[] = ['gpt-4o-mini', 'o1', 'o1-mini'];
  const [error, setError] = useState<string | null>(null);
  const [isEvaluating, setIsEvaluating] = useState(false);
  const [isAuthorNGramAnalyzing, setIsAuthorNGramAnalyzing] = useState(false);
  const [isStoryBeatAnalyzing, setIsStoryBeatAnalyzing] = useState(false);
  // const [isCharacterNGramAnalyzing, setIsCharacterNGramAnalyzing] =
  // useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  // TODO: This is kind of hacky strings based on the titles of the sections and is really brittle.
  const [openSections, setOpenSections] = useState<{ [key: string]: boolean }>({
    evaluation: false,
    overall: false,
    character: false,
    plot: false,
    promise: false,
    quality: false,
    authorngramanalysis: false,
    characterngramanalysis: false,
    storybeat: false,
  });
  const [isAcceptingOrRejecting, setIsAcceptingOrRejecting] = useState(false);

  const hasAnyPendingAnalysis = useCallback(() => {
    if (!currentVersionDetails) return false;
    return (
      currentVersionDetails.evaluationPending ||
      currentVersionDetails.authorNGramAnalysisPending ||
      currentVersionDetails.characterNGramAnalysisPending ||
      currentVersionDetails.storyBeatAnalysisPending
    );
  }, [currentVersionDetails]);

  const fetchFileDetails = useCallback(async () => {
    if (!id) return;

    setIsLoading(true);
    try {
      const response = await post<UploadedFileData>(`/files/book/${id}`, {});
      setFileDetails(response);
      setCurrentVersionDetails(response.mostRecentDetails);
      setOpenSections({
        overall: !!response.mostRecentDetails?.evaluation,
        authorngramanalysis: !!response.mostRecentDetails?.authorNGramAnalysis,
        characterngramanalysis:
          !!response.mostRecentDetails?.characterNGramAnalysis,
      });
      setError(null);
    } catch (error) {
      console.error('Error fetching file details:', error);
      setError('Failed to load file details. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchFileDetails();
  }, [fetchFileDetails]);

  const fetchVersionDetails = useCallback(async (versionId: string) => {
    setIsLoading(true);
    try {
      const response = await post<TBookDetails>(
        `/files/book/version/${versionId}`,
        {}
      );
      setCurrentVersionDetails(response);
      setError(null);
    } catch (error) {
      console.error('Error fetching version details:', error);
      setError('Failed to load version details. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    // Only start polling if there's a pending analysis
    if (!hasAnyPendingAnalysis()) return;

    // Set up polling interval
    const pollInterval = setInterval(() => {
      if (currentVersionDetails) {
        fetchVersionDetails(currentVersionDetails.versionId);
      }
    }, 30000); // Poll every 30 seconds

    // Cleanup function to clear interval
    return () => {
      clearInterval(pollInterval);
    };
  }, [hasAnyPendingAnalysis, currentVersionDetails, fetchVersionDetails]);

  const handleVersionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedVersionId = event.target.value;
    fetchVersionDetails(selectedVersionId);
  };

  const isAdmin = useSelector(
    (state: RootState) => !!state.userAuth.user?.admin
  );

  const handleModelChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedModel = event.target.value as TLlmModel;
    setLlmModel(selectedModel);
  };

  const handleEvaluationSubmit = async () => {
    if (!id || !currentVersionDetails) return;

    setIsEvaluating(true);
    try {
      await post(`/evaluation`, {
        bookVersionId: currentVersionDetails.versionId,
        llmModel: llmModel,
      });
      await fetchVersionDetails(currentVersionDetails.versionId);
    } catch (error) {
      console.error('Error submitting evaluation:', error);
      setError('Failed to submit evaluation. Please try again later.');
    } finally {
      setIsEvaluating(false);
    }
  };

  const handleAuthorNGramAnalysis = async () => {
    if (!id || !currentVersionDetails) return;

    setIsAuthorNGramAnalyzing(true);
    try {
      await post(`/ngram/author`, {
        bookVersionId: currentVersionDetails.versionId,
      });
      await fetchVersionDetails(currentVersionDetails.versionId);
    } catch (error) {
      console.error('Error submitting author NGram analysis:', error);
      setError(
        'Failed to submit author NGram analysis. Please try again later.'
      );
    } finally {
      setIsAuthorNGramAnalyzing(false);
    }
  };

  const handleStoryBeatAnalysis = async () => {
    if (!id || !currentVersionDetails) return;

    setIsStoryBeatAnalyzing(true);
    try {
      await post(`/story-beats`, {
        bookVersionId: currentVersionDetails.versionId,
        llmModel: llmModel,
      });
      await fetchVersionDetails(currentVersionDetails.versionId);
    } catch (error) {
      console.error('Error submitting story beat analysis:', error);
      setError('Failed to submit story beat analysis. Please try again later.');
    } finally {
      setIsStoryBeatAnalyzing(false);
    }
  };

  // const handleCharacterNGramAnalysis = async () => {
  //   if (!id || !currentVersionDetails) return;

  //   setIsCharacterNGramAnalyzing(true);
  //   try {
  //     await post(`/ngram/character`, {
  //       bookVersionId: currentVersionDetails.versionId,
  //     });
  //     await fetchVersionDetails(currentVersionDetails.versionId);
  //   } catch (error) {
  //     console.error('Error submitting character NGram analysis:', error);
  //     setError(
  //       'Failed to submit character NGram analysis. Please try again later.'
  //     );
  //   } finally {
  //     setIsCharacterNGramAnalyzing(false);
  //   }
  // };

  const handleManuscriptAction = async (
    action: 'bookAccepted' | 'bookRejected'
  ) => {
    if (!id) return;

    setIsAcceptingOrRejecting(true);
    try {
      await post(`/files/book/${id}/action`, {
        action,
      });
      // Optionally refresh the file details after action
      await fetchFileDetails();
    } catch (error) {
      console.error('Error processing manuscript action:', error);
      setError('Failed to process manuscript action. Please try again later.');
    } finally {
      setIsAcceptingOrRejecting(false);
    }
  };

  const toggleSection = (section: string) => {
    console.log(section);
    setOpenSections(prev => ({ ...prev, [section]: !prev[section] }));
  };

  const renderEvaluationSection = (
    sectionName: string,
    evaluation:
      | TBookEvalOverall
      | TBookEvalCharacter
      | TBookEvalPlot
      | TBookEvalPromise
      | TBookEvalQuality
      | null
  ) => {
    if (!evaluation) return null;
    const lowerCaseName = sectionName.toLowerCase();

    return (
      <EvaluationSection
        sectionName={sectionName}
        evaluation={evaluation}
        isOpen={openSections[lowerCaseName]}
        onToggle={() => toggleSection(lowerCaseName)}
      />
    );
  };

  const renderNGramAnalysisSection = (
    title: string,
    analysis: TAuthorNGrams | null,
    isPending: boolean,
    handleAnalysis: () => void,
    isAnalyzing: boolean
  ) => {
    const sectionKey = title.toLowerCase().replaceAll(' ', '');
    return (
      <CollapsibleSection
        title={title}
        isOpen={openSections[sectionKey]}
        onToggle={() => toggleSection(sectionKey)}
      >
        <p className={styles.sectionDescription}>
          {title} provides insights into the writing style and patterns used in
          the book, showing the most common word patterns of different lengths.
        </p>
        {isPending ? (
          <p className={styles.pendingAnalysis}>
            Your insights will be ready in a few minutes, hang tight and we'll
            refresh when they are ready.
          </p>
        ) : analysis ? (
          <div className={styles.analysisContainer}>
            {typeof analysis === 'string' ? (
              <pre className={styles.analysisContent}>{analysis}</pre>
            ) : (
              <NGramAnalysisViewer data={analysis} />
            )}
            {!(title === 'Author NGram Analysis') && (
              <button
                className={styles.analysisButton}
                onClick={handleAnalysis}
                disabled={isAnalyzing}
              >
                {isAnalyzing ? 'Analyzing...' : 'Re-run Analysis'}
              </button>
            )}
          </div>
        ) : (
          <div className={styles.noAnalysis}>
            <p>No analysis available.</p>
            <button
              className={styles.analysisButton}
              onClick={handleAnalysis}
              disabled={isAnalyzing}
            >
              {isAnalyzing ? 'Analyzing...' : 'Start Analysis'}
            </button>
          </div>
        )}
      </CollapsibleSection>
    );
  };

  const handleDeleteBook = async () => {
    if (!id) return;

    setIsDeleting(true);
    try {
      await post(`/files/book/${id}/delete`, {});
      // Redirect to submissions after a brief delay to show success message
      setTimeout(() => {
        navigate('/submissions');
      }, 2000);
    } catch (error) {
      console.error('Error deleting book:', error);
      setError('Failed to delete book. Please try again later.');
    } finally {
      setIsDeleting(false);
    }
  };

  const statusToHuman = (
    status: 'bookAccepted' | 'bookRejected' | 'bookPending'
  ) => {
    switch (status) {
      case 'bookAccepted':
        return 'Accepted';
      case 'bookRejected':
        return 'Rejected';
      case 'bookPending':
        return 'Pending';
      default:
        return 'Unknown';
    }
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (!fileDetails || !currentVersionDetails) {
    return (
      <div className={styles.errorMessage}>No file details available.</div>
    );
  }

  return (
    <div className={styles.container}>
      <Link to="/submissions" className={styles.backLink}>
        &larr; Back to Submissions
      </Link>
      <h1 className={styles.title}>{fileDetails.title}</h1>
      {error && <p className={styles.errorMessage}>{error}</p>}
      <div className={styles.card}>
        <h2 className={styles.sectionTitle}>Book Details</h2>
        <div className={styles.selectors}>
          {fileDetails.versions.length > 1 && (
            <div className={styles.versionSelector}>
              <label htmlFor="versionSelect">Version: </label>
              <select
                id="versionSelect"
                value={currentVersionDetails.versionId}
                onChange={handleVersionChange}
              >
                {fileDetails.versions.map(([versionNumber, versionId]) => (
                  <option key={versionId} value={versionId}>
                    Version {versionNumber}
                  </option>
                ))}
              </select>
            </div>
          )}
          {isAdmin && (
            <div className={styles.modelSelector}>
              <label htmlFor="modelSelect">Model: </label>
              <select
                id="modelSelect"
                value={llmModel}
                onChange={handleModelChange}
              >
                {llmModelOptions.map(model => (
                  <option key={model} value={model}>
                    {model}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>
        <ul className={styles.detailsList}>
          {currentVersionDetails.currentStatus &&
            currentVersionDetails.currentStatus !== 'bookPending' && (
              <li className={styles.detailsItem}>
                <strong>Status:</strong>{' '}
                {statusToHuman(currentVersionDetails.currentStatus)}
              </li>
            )}
          <li className={styles.detailsItem}>
            <strong>Upload Date:</strong>{' '}
            {new Date(fileDetails.uploadDate).toLocaleDateString()}
          </li>
          {currentVersionDetails.author && (
            <li className={styles.detailsItem}>
              <strong>Author:</strong> {currentVersionDetails.author}
            </li>
          )}
          {currentVersionDetails.wordCount && (
            <li className={styles.detailsItem}>
              <strong>Word Count:</strong>{' '}
              {currentVersionDetails.wordCount.toLocaleString()}
            </li>
          )}
          {currentVersionDetails.chapterCount && (
            <li className={styles.detailsItem}>
              <strong>Chapter Count:</strong>{' '}
              {currentVersionDetails.chapterCount}
            </li>
          )}
          {currentVersionDetails.firstLine && (
            <li className={styles.detailsItem}>
              <strong>Opening:</strong> {currentVersionDetails.firstLine}
            </li>
          )}
          <div className={styles.actionButtons}>
            <button
              onClick={() => handleManuscriptAction('bookAccepted')}
              disabled={isAcceptingOrRejecting}
              className={`${styles.actionButton} ${styles.acceptButton}`}
            >
              {isAcceptingOrRejecting ? 'Processing...' : 'Accept'}
            </button>
            <button
              onClick={() => handleManuscriptAction('bookRejected')}
              disabled={isAcceptingOrRejecting}
              className={`${styles.actionButton} ${styles.rejectButton}`}
            >
              {isAcceptingOrRejecting ? 'Processing...' : 'Reject'}
            </button>
          </div>
        </ul>

        <h2 className={styles.sectionTitle}>Analysis</h2>
        <div className={styles.analysisSections}>
          {renderNGramAnalysisSection(
            'Author NGram Analysis',
            currentVersionDetails.authorNGramAnalysis,
            currentVersionDetails.authorNGramAnalysisPending,
            handleAuthorNGramAnalysis,
            isAuthorNGramAnalyzing
          )}

          <CollapsibleSection
            title="Evaluation"
            isOpen={openSections.evaluation}
            onToggle={() => toggleSection('evaluation')}
          >
            <p className={styles.sectionDescription}>
              Evaluations over a number of different dimensions of the story.
            </p>
            {currentVersionDetails.evaluation ? (
              <div className={styles.evaluationSections}>
                {renderEvaluationSection(
                  'Overall',
                  currentVersionDetails.evaluation.overall
                )}
                {renderEvaluationSection(
                  'Character',
                  currentVersionDetails.evaluation.character
                )}
                {renderEvaluationSection(
                  'Plot',
                  currentVersionDetails.evaluation.plot
                )}
                {renderEvaluationSection(
                  'Promise',
                  currentVersionDetails.evaluation.promise
                )}
                {renderEvaluationSection(
                  'Quality',
                  currentVersionDetails.evaluation.quality
                )}
              </div>
            ) : currentVersionDetails.evaluationPending ? (
              <p className={styles.pendingEvaluation}>
                Your insights will be ready in a few minutes, hang tight and
                we'll refresh when they are ready.
              </p>
            ) : (
              <div className={styles.noEvaluation}>
                <p>No evaluation available.</p>
                <button
                  className={styles.evaluationButton}
                  onClick={handleEvaluationSubmit}
                  disabled={isEvaluating}
                >
                  {isEvaluating ? 'Evaluating...' : 'Start Evaluation'}
                </button>
              </div>
            )}
          </CollapsibleSection>
          <CollapsibleSection
            title="Story Beat Analysis"
            isOpen={openSections.storybeat}
            onToggle={() => toggleSection('storybeat')}
          >
            <p className={styles.sectionDescription}>
              Story beat analysis maps out the key narrative moments and their
              interconnections, showing how different types of story progression
              (character-driven, plot-driven, or world-driven) work together
              through the manuscript.
            </p>
            {currentVersionDetails.storyBeatAnalysisPending ? (
              <p className={styles.pendingAnalysis}>
                Your insights will be ready in a few minutes, hang tight and
                we'll refresh when they are ready.
              </p>
            ) : currentVersionDetails.storyBeatAnalysis ? (
              <div className={styles.analysisContainer}>
                <StoryBeatVisualizer
                  data={currentVersionDetails.storyBeatAnalysis}
                />
              </div>
            ) : (
              <div className={styles.noAnalysis}>
                <p>No story beat analysis available.</p>
                <button
                  className={styles.analysisButton}
                  onClick={handleStoryBeatAnalysis}
                  disabled={isStoryBeatAnalyzing}
                >
                  {isStoryBeatAnalyzing ? 'Analyzing...' : 'Start Analysis'}
                </button>
              </div>
            )}
          </CollapsibleSection>
          <p>Character Analysis Coming Soon</p>
          {/* COMING SOON
          {renderNGramAnalysisSection(
            'Character NGram Analysis',
            currentVersionDetails.characterNGramAnalysis,
            currentVersionDetails.characterNGramAnalysisPending,
            handleCharacterNGramAnalysis,
            isCharacterNGramAnalyzing
          )} */}
        </div>
        <div className={styles.deleteSection}>
          <h2 className={styles.sectionTitle}>Danger Zone</h2>
          <p className={styles.deleteWarning}>
            Deleting this book will permanently remove it and all its versions.
            This action cannot be undone.
          </p>

          <button
            className={styles.deleteButton}
            onClick={() => setShowDeleteDialog(true)}
            disabled={isDeleting}
          >
            {isDeleting ? 'Deleting...' : 'Delete Book'}
          </button>

          <AlertDialog
            isOpen={showDeleteDialog}
            title="Are you absolutely sure?"
            description={`This will permanently delete "${fileDetails.title}" and all its associated data. This action cannot be undone.`}
            onConfirm={handleDeleteBook}
            onCancel={() => setShowDeleteDialog(false)}
            confirmText="Yes, Delete Book"
            cancelText="Cancel"
            isConfirming={isDeleting}
          />
        </div>
      </div>
    </div>
  );
};
