import React, { useMemo } from 'react';
import { Dot } from 'lucide-react';
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  ZAxis,
  Tooltip,
  Cell,
} from 'recharts';
import styles from './StoryBeatVisualizer.module.css';
import { TConnectionType, TStoryBeats } from '../../api/generated/types/common';

interface StoryBeatVisualizerProps {
  data: TStoryBeats;
}

const StoryBeatVisualizer: React.FC<StoryBeatVisualizerProps> = ({ data }) => {
  const scatterData = useMemo(() => {
    return data.beats.map(beat => ({
      x: beat.number,
      y: beat.agencyType === 'character' ? 1 : 2,
      z: beat.genreExpectation === 'expected' ? 1 : 2,
      ...beat,
    }));
  }, [data.beats]);

  const agencyColors = {
    character: '#4299e1', // blue
    external: '#ed8936', // orange
  };

  const getConnectionLabel = (type: TConnectionType) => {
    switch (type) {
      case 'therefore':
        return 'Therefore';
      case 'but':
        return 'But';
      case 'andThen':
        return 'And Then';
      case 'because':
        return 'Because';
      default:
        return type;
    }
  };

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;

      return (
        <div className={styles.tooltip}>
          <h3 className={styles.tooltipTitle}>Beat {data.number}</h3>
          <p className={styles.tooltipDescription}>{data.description}</p>
          <p className={styles.tooltipDetail}>
            Chapter: {data.chapterLocation}
          </p>
          <p className={styles.tooltipDetail}>
            Function: {data.narrativeFunction}
          </p>
          <p className={styles.tooltipDetail}>Agency: {data.agencyType}</p>
          <p className={styles.tooltipDetail}>
            Genre Expectation: {data.genreExpectation}
          </p>
        </div>
      );
    }
    return null;
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h3 className={styles.title}>Story Beat Visualization</h3>
        <div className={styles.legend}>
          <div className={styles.legendSection}>
            <h4 className={styles.legendTitle}>Agency Type:</h4>
            <div className={styles.legendItems}>
              <div className={styles.legendItem}>
                <Dot className={styles.characterDot} />
                <span className={styles.legendText}>Character-driven</span>
              </div>
              <div className={styles.legendItem}>
                <Dot className={styles.externalDot} />
                <span className={styles.legendText}>External</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.chartContainer}>
        <ScatterChart
          width={800}
          height={400}
          margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
        >
          <XAxis
            type="number"
            dataKey="x"
            name="Beat Number"
            label={{ value: 'Beat Sequence', position: 'bottom' }}
          />
          <YAxis
            type="number"
            dataKey="y"
            name="Agency Type"
            domain={[0, 3]}
            ticks={[1, 2]}
            tickFormatter={value => (value === 1 ? 'Character' : 'External')}
          />
          <ZAxis
            type="number"
            dataKey="z"
            range={[100, 500]}
            name="Genre Expectation"
          />
          <Tooltip content={<CustomTooltip />} />
          <Scatter name="Story Beats" data={scatterData}>
            {scatterData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={agencyColors[entry.agencyType]}
              />
            ))}
          </Scatter>
        </ScatterChart>
      </div>
      <div className={styles.connectionsContainer}>
        <h4 className={styles.connectionsTitle}>Beat Connections</h4>
        <div className={styles.connectionsList}>
          {data.beatConnections.map((connection, index) => {
            const fromBeat = data.beats.find(
              b => b.id === connection.fromBeatId
            );
            const toBeat = data.beats.find(b => b.id === connection.toBeatId);
            return (
              <div key={index} className={styles.connectionItem}>
                <p className={styles.connectionHeader}>
                  <span className={styles.connectionBeats}>
                    Beat {fromBeat?.number} → Beat {toBeat?.number}
                  </span>
                  <span className={styles.connectionDivider}>|</span>
                  <span className={styles.connectionType}>
                    {getConnectionLabel(connection.connectionType)}
                  </span>
                  <span className={styles.connectionDivider}>|</span>
                  <span className={styles.connectionStrength}>
                    Strength: {connection.strength}/10
                  </span>
                </p>
                <p className={styles.connectionExplanation}>
                  {connection.explanation}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default StoryBeatVisualizer;
