// src/mocks/handlers.ts
import { http, delay, HttpResponse } from 'msw';
import { LoginCredentials } from '../pages/Login/Login';
import { SignupCredentials } from '../pages/Signup/Signup';
import { mockFiles } from './data/fileData';

export interface User {
  id: string;
  name: string;
  email: string;
  admin: boolean;
  organizationId: string;

  // Add other properties as needed
}

interface ConfirmUploadRequest {
  fileId: string;
}

let user: User | null = null;
let users = [{ id: 1, email: 'test@example.com', password: 'somethingsimple' }];

export const handlers = [
  // This handler will delay all responses to simulate a real-world scenario
  http.all('*', async () => {
    await delay(Math.floor(Math.random() * (1000 - 10 + 1) + 10));
  }),

  http.post('/signin', async ({ request }) => {
    const { email, password } = (await request.json()) as LoginCredentials;
    if (email === 'test' && password === 'somethingsimple') {
      user = {
        id: '50965634-80e5-11ef-b059-97dfaf792bdd',
        name: 'John Doe',
        email: email,
        admin: false,
        organizationId: '50965634-80e5-11ef-b059-97dfaf792bde',
      };
      return HttpResponse.json({ success: true, user });
    }
    return HttpResponse.json(
      { success: false, message: 'Invalid email or password' },
      { status: 401 }
    );
  }),

  http.post('/logout', () => {
    user = null;
    return HttpResponse.json({ success: true });
  }),

  http.post('/signup', async ({ request }) => {
    const { email, password } = (await request.json()) as SignupCredentials;

    if (users.some(user => user.email === email)) {
      return HttpResponse.json(
        { success: false, message: 'Email already exists' },
        { status: 400 }
      );
    }

    const newUser = { id: users.length + 1, email, password };
    users.push(newUser);

    return HttpResponse.json({
      success: true,
      user: { id: newUser.id, email: newUser.email },
    });
  }),

  http.post('/get-initial-data', async ({ request }) => {
    users.push(users[0]);

    return HttpResponse.json({ success: true, user: users[0] });
  }),

  // Handler for fetching the list of files
  http.post('/files/:id/all', () => {
    return HttpResponse.json(
      mockFiles.map(({ id, title, uploadDate, versions }) => ({
        id,
        title,
        uploadDate,
        versions,
      }))
    );
  }),
  http.post('/files/undefined/all', () => {
    return HttpResponse.json(
      mockFiles.map(({ id, title, uploadDate, versions }) => ({
        id,
        title,
        uploadDate,
        versions,
      }))
    );
  }),
  // Handler for fetching individual file details
  http.post('/files/book/:id', ({ params }) => {
    const { id } = params;
    const file = mockFiles.find(f => f.id === id);

    if (file) {
      return HttpResponse.json(file);
    } else {
      return new HttpResponse(JSON.stringify({ message: 'File not found' }), {
        status: 404,
      });
    }
  }),

  // Handler for getting a pre-signed URL
  http.post('/files/:orgId/get-presigned-url', async () => {
    return HttpResponse.json({
      presignedUrl: 'https://mock-r2-storage.com/presigned-url',
      fileId: 'mock-file-id-' + Date.now(),
    });
  }),

  // Handler for confirming the upload
  http.post('/files/:id/confirm-upload', async ({ request }) => {
    const { fileId } = (await request.json()) as ConfirmUploadRequest;
    return HttpResponse.json({
      id: fileId,
      name: 'mock-file-name.txt',
      uploadDate: new Date().toISOString(),
    });
  }),

  // Handler for the direct upload to R2 (mocking the external service)
  http.put('https://mock-r2-storage.com/presigned-url', async () => {
    return new HttpResponse(null, { status: 200 });
  }),
];
